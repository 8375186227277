module.exports = (function Overrides() {
  BackboneOriginal.View = (function BackbonePatchedView(View) {
    return View.extend({
      constructor: function constructor(options) {
        this.options = options || {};

        View.apply(this, arguments);
      },

      overrideDefaults: function overrideDefaults(defaults) {
        // assign properties. look to this.options, fall back on defaults
        Object.keys(defaults || {}).forEach(
          function name(key) {
            let def = defaults[key];
            let override = this.options[key];
            let value =
              !_.isArray(override) &&
              _.isObject(override) &&
              !_.isFunction(override) &&
              _.isObject(def) &&
              !_.isFunction(def)
                ? _.extend({}, def, override)
                : override;

            this[key] = _.isUndefined(override) ? def : value;
          }.bind(this),
        );
      },
    });
  }(BackboneOriginal.View));
  // Patch options
  BackboneOriginal.Collection = (function BackbonePatchedCollection(Collection) {
    return Collection.extend({
      constructor: function constructor(models, options) {
        this.options = options || {};

        this.url = this.localizeEndpoint(this.endpoint);

        Collection.apply(this, arguments);
      },

      localizeEndpoint: function localizeEndpoint() {
        // attempt to find the language prefix
        let lang = window.URL_LANGUAGE_PREFIX;
        // prefix the endpoint if found (add leading/trailing slashes)
        return '/' + (lang ? lang + '/' + this.endpoint : this.endpoint) + '/';
      },
    });
  }(BackboneOriginal.Collection));

  return BackboneOriginal;
}());
